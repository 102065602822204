import React, { useEffect, useState, Fragment } from "react"
import AdminLayout from "../../layouts/AdminLayout"

import { Grid, Paper, TextField } from "@material-ui/core"
import { Link } from "gatsby"
import axios from "axios"
import { navigate } from "gatsby"
import Pagination from "@material-ui/lab/Pagination"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import host from "../../config/index"

const Clients = () => {
  const [clients, setClients] = useState([])
  const [clientsCopy, setClientsCopy] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [tab, setTab] = useState("active")
  const [page, setPage] = React.useState(1)
  const [totalPage, setTotalPage] = React.useState(0)
  const [text, setText] = React.useState("")
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [mobile, setMobile] = React.useState("")
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleChange = (event, value) => {
    setPage(value)
  }
  useEffect(() => {
    getClients()
  }, [text, tab, page])

  const getClients = () => {
    let query = ""
    query = query + "?pageNumber=" + page
    query = query + "&pageSize=10"
    if (text != "" && text != undefined) {
      query = query + "&clientId=" + text
    }
    query = query + "&status=" + tab

    axios
      .get(`${host}/admin/clients${query}`, {
        headers: { Authorization: `JWT ${localStorage.getItem("token")}` },
      })
      .then(({ data }) => {
        setClients(data.data.data)
        setClientsCopy(data.data.data)
        setTotalPage(Math.ceil(data.data.totalCount / 10))
      })
  }
  const handleClick = client => {
    navigate(`/admin/accountStatement`, {
      state: { client },
    })
  }

  const handleSelect = client => {
    if (!selectedRows.includes(client.clientId))
      setSelectedRows([...selectedRows, client.clientId])
    else {
      var array = selectedRows.filter(row => {
        if (row != client.clientId) return row
      })
      setSelectedRows(array)
    }
  }

  const _filter = status => {
    // var filteredClients = clientsCopy.filter(c => {
    //   if (c.status == status) return c
    // })
    // setClients(filteredClients)
    setTab(status)
  }

  const _deleteClient = () => {
    selectedRows.map(id => {
      axios
        .delete(`${host}/admin/clients/${id}`, {
          headers: { Authorization: `JWT ${localStorage.getItem("token")}` },
        })
        .then(({ data }) => console.log(data))
    })
  }

  const _addClient = () => {
    axios
      .post(
        `${host}/admin/clients/`,
        { name, email, phone: mobile, password, status: "active" },
        {
          headers: { Authorization: `JWT ${localStorage.getItem("token")}` },
        }
      )
      .then(({ data }) => setOpen(false))
  }

  const Table = () => {
    return (
      <Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Add Client</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                style={{ marginLeft: 20 }}
                id="standard-basic"
                label="Name"
                onChange={e => setName(e.target.value)}
                value={name}
              />
              <TextField
                style={{ marginLeft: 20 }}
                id="standard-basic"
                label="Email"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
              <TextField
                style={{ marginLeft: 20 }}
                id="standard-basic"
                label="Mobile"
                onChange={e => setMobile(e.target.value)}
                value={mobile}
              />
              <TextField
                style={{ marginLeft: 20 }}
                id="standard-basic"
                label="Password"
                onChange={e => setPassword(e.target.value)}
                value={password}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              cancel
            </Button>
            <Button onClick={_addClient} color="primary" autoFocus>
              Create
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          justify="flex-end"
          style={{
            padding: 20,
            width: "80%",
          }}
        >
          {" "}
          <button
            type="button"
            className="btn btn-sm"
            style={{
              marginLeft: 10,
              display: "block",
              borderRadius: 10,
              paddingLeft: 20,
              paddingRight: 20,
              color: "rgb(104,102,233)",
              borderColor: "rgb(104,102,233)",
              padding: 6,
              "&:hover": {
                color: "rgb(104,102,233)",
                borderColor: "rgb(104,102,233)",
                backgroundColor: "#FFF",
              },
            }}
            onClick={handleClickOpen}
          >
            ADD USER
          </button>
        </Grid>

        <Paper
          style={{
            background: "rgb(104,102,233)",
            background:
              "linear-gradient(0deg, rgba(104,102,233,1) 0%, rgba(106,165,227,1) 100%)",
            height: "100%",
            padding: 20,
            width: "80%",
          }}
        >
          <p style={{ textAlign: "left", fontWeight: "bold" }}>Users</p>
          <span
            style={
              tab == "active"
                ? {
                    borderBottom: "2px solid #FFF",
                    marginLeft: 20,
                    color: "#fff",
                    float: "left",
                    cursor: "pointer",
                  }
                : {
                    marginLeft: 20,
                    color: "#fff",
                    float: "left",
                    cursor: "pointer",
                  }
            }
            onClick={() => _filter("active")}
          >
            Active
          </span>

          <span
            style={
              tab == "inactive"
                ? {
                    borderBottom: "2px solid #FFF",
                    marginLeft: 20,
                    color: "#fff",
                    float: "left",
                    cursor: "pointer",
                  }
                : {
                    marginLeft: 20,
                    color: "#fff",
                    float: "left",
                    cursor: "pointer",
                  }
            }
            onClick={() => _filter("inactive")}
          >
            Inactive
          </span>

          <button
            type="button"
            className="btn btn-danger btn-sm"
            style={{
              marginLeft: 10,
              float: "right",
              margin: 20,
              borderRadius: 10,
              paddingLeft: 20,
              paddingRight: 20,
            }}
            onClick={() => _deleteClient()}
          >
            Delete
          </button>

          <Link
            to={`https://docs.google.com/spreadsheets/d/1WF1xhWyyL5t6QgOZNK26MTEC8_LPyLawdJNG0n8Id4M/edit#gid=0`}
            target="_blank"
          >
            <button
              type="button"
              className="btn btn-light btn-sm"
              style={{
                marginLeft: 10,
                float: "right",
                margin: 20,
                borderRadius: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              Edit
            </button>
          </Link>
          <input
            style={{ margin: 20, padding: 3, float: "right" }}
            placeholder="Client ID"
            onChange={e => {
              setText(e.target.value)
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
              <span style={{ color: "#FFF" }}>select</span>
            </Grid>{" "}
            <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
              <span style={{ color: "#FFF" }}>Sl.No</span>
            </Grid>
            <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
              <span style={{ color: "#FFF" }}>Name</span>
            </Grid>
            <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
              <span style={{ color: "#FFF" }}>Client ID</span>
            </Grid>
            <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
              <span style={{ color: "#FFF" }}>Phone</span>
            </Grid>
            <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
              <span style={{ color: "#FFF" }}>Email</span>
            </Grid>
            <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
              <span style={{ color: "#FFF" }}>Password</span>
            </Grid>
            <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
              <span style={{ color: "#FFF" }}>View</span>
            </Grid>
          </Grid>
          <hr style={{ border: "1px solid #fff" }} />

          {clients.map((client, index) => {
            return (
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="invalidCheck2"
                        required
                        onClick={() => handleSelect(client)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
                    <span style={{ color: "#FFF" }}>{index + 1}</span>
                  </Grid>
                  <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
                    <span style={{ color: "#FFF" }}>{client.name}</span>
                  </Grid>
                  <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
                    <span style={{ color: "#FFF" }}>{client.clientId}</span>
                  </Grid>
                  <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
                    <span style={{ color: "#FFF" }}>{client.phone}</span>
                  </Grid>{" "}
                  <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
                    <span style={{ color: "#FFF" }}>{client.email}</span>
                  </Grid>
                  <Grid item xs={12} lg={2} xl={2} md={2} sm={2}>
                    <span style={{ color: "#FFF" }}>{client.password}</span>
                  </Grid>
                  <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
                    <button
                      type="button"
                      class="btn btn-light btn-sm"
                      onClick={() => handleClick(client)}
                    >
                      View
                    </button>
                  </Grid>
                </Grid>
                <hr style={{ border: "1px solid #fff" }} />
              </React.Fragment>
            )
          })}
          <Pagination
            count={totalPage}
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </Paper>
      </Fragment>
    )
  }

  return (
    <AdminLayout>
      <div style={{ padding: 20, width: "100%" }}>
        <Grid container spacing={0} align="center" justify="center">
          <Grid item xs={12}>
            <div className="row">
              {[
                {
                  component: Table(),
                },
              ].map(slide => {
                return (
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    style={{
                      width: "100%",
                      paddingBottom: 100,
                    }}
                  >
                    {slide.component}
                  </Grid>
                )
              })}
            </div>
          </Grid>
        </Grid>
      </div>
    </AdminLayout>
  )
}

export default Clients
